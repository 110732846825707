import React from 'react';
import PropTypes from 'prop-types';
import { basePageWrap } from '../BasePage';
import AnnualRing from '../../components/AnnualRing';
import StreamField from '../../components/StreamField';
import CardExternalProject from '../../components/CardExternalProject';
import styles from './PageHomeFolkhem.module.scss';

class PageHomeFolkhem extends React.PureComponent {
    render() {
        const { items, projectHero } = this.props;

        return (
            <div className={styles["PageHomeFolkhem"]}>
                <AnnualRing />
                {projectHero && (
                    <div className={styles["PageHomeFolkhem__Hero"]}>
                        <CardExternalProject {...projectHero} />
                    </div>
                )}

                <div className={styles["PageHomeFolkhem__StreamField"]}>
                    <StreamField items={items} />
                </div>
            </div>
        );
    }
}

PageHomeFolkhem.propTypes = {
    items: PropTypes.array,
    projectHero: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

PageHomeFolkhem.defaultProps = {
    items: [],
};

export default basePageWrap(PageHomeFolkhem);
